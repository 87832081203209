import { IBaseDeleteRequestModel } from "../models/request/base/deleteRequest";
import { sf_chinhsachhocphi } from "../models/response/category/sf_chinhsachhocphi";
import { apiClient } from "./apiClient";

export const SF_CHINHSACHHOCPHI_SELECT_ALL_API = "sf_chinhsachhocphi/select_all";
export const SF_CHINHSACHHOCPHI_INSERT_API = "sf_chinhsachhocphi/insert";
export const SF_CHINHSACHHOCPHI_UPDATE_API = "sf_chinhsachhocphi/update";
export const SF_CHINHSACHHOCPHI_DELETE_API = "sf_chinhsachhocphi/delete";

export const chinhSachHocPhiApi = {
    select_all: () => {
        return apiClient.post(SF_CHINHSACHHOCPHI_SELECT_ALL_API)
    },
    insert: (data: sf_chinhsachhocphi) => {
        return apiClient.post(SF_CHINHSACHHOCPHI_INSERT_API, data)
    },
    update: (data: sf_chinhsachhocphi) => {
        return apiClient.post(SF_CHINHSACHHOCPHI_UPDATE_API, data)
    },
    delete: (data: number[]) => {
        const model: IBaseDeleteRequestModel = {
            ids: data
        }
        return apiClient.post(SF_CHINHSACHHOCPHI_DELETE_API, model)
    }
}