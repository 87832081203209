import { showNotify } from "../../helpers/toast";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { ChinhSachHocPhiActionTypeIds, ChinhSachHocPhiActionTypes } from "../action-types/chinhSachHocPhiActionTypes";
import { ChinhSachHocPhiPageState } from "../page-state-model/chinhSachHocPhiPageState";
const initialState: ChinhSachHocPhiPageState = {
    sf_chinhsachhocphis: [],
    sf_chinhsachhocphi_selected_ids: [],
    status: PageBaseStatus.is_completed
}
export const chinhSachHocPhiReducer = (state: ChinhSachHocPhiPageState = initialState, action: ChinhSachHocPhiActionTypes): ChinhSachHocPhiPageState => {
    switch (action.type) {
        case ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_LOAD_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_chinhsachhocphis: action.payload
            }
        case ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_LOAD_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case ChinhSachHocPhiActionTypeIds.NHOMKHOANOP_SHOW_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: true,
                sf_chinhsachhocphi_editing: action.payload
            }
        case ChinhSachHocPhiActionTypeIds.NHOMKHOANOP_CLOSE_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: false,
                // sf_hinhthucmiengiam_editing: undefined
            }
        case ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_SHOW_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: action.payload
            }
        case ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_SAVE_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving
            }
        case ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_SAVE_SUCCESS:
            showNotify({
                message: `${action.payload.id > 0 ? "Cập nhật " : "Thêm mới "} thành công`,
                type: "success"
            })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_chinhsachhocphi_editing: undefined,
                is_show_detail_modal: false
            }
        case ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_SAVE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }

        case ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_DELETE_START:
            return {
                ...state,
                status: PageBaseStatus.is_deleting
            }
        case ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_DELETE_SUCCESSS:
            showNotify({ message: `Xóa thành công ${action.payload.length} khoản nộp`, type: "success" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }
        case ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_DELETE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_CHANGE_SELECTED_IDS:
            return {
                ...state,
                sf_chinhsachhocphi_selected_ids: action.payload
            }
        default:
            return state;
    }
}