import { sf_chinhsachhocphi } from "../../models/response/category/sf_chinhsachhocphi"
export enum ChinhSachHocPhiActionTypeIds {
    CHINHSACHHOCPHI_LOAD_START = "CHINHSACHHOCPHI_LOAD_START",
    CHINHSACHHOCPHI_LOAD_SUCCESS = "CHINHSACHHOCPHI_LOAD_SUCCESS",
    CHINHSACHHOCPHI_LOAD_ERROR = "CHINHSACHHOCPHI_LOAD_ERROR",

    NHOMKHOANOP_SHOW_DETAIL_MODAL = "NHOMKHOANOP_SHOW_DETAIL_MODAL",
    NHOMKHOANOP_CLOSE_DETAIL_MODAL = "NHOMKHOANOP_CLOSE_DETAIL_MODAL",
    CHINHSACHHOCPHI_SHOW_DELETE_CONFIRM = "CHINHSACHHOCPHI_SHOW_DELETE_CONFIRM",

    CHINHSACHHOCPHI_SAVE_START = "CHINHSACHHOCPHI_SAVE_START",
    CHINHSACHHOCPHI_SAVE_SUCCESS = "CHINHSACHHOCPHI_SAVE_SUCCESS",
    CHINHSACHHOCPHI_SAVE_ERROR = "CHINHSACHHOCPHI_SAVE_ERROR",

    CHINHSACHHOCPHI_DELETE_START = "CHINHSACHHOCPHI_DELETE_START",
    CHINHSACHHOCPHI_DELETE_SUCCESSS = "CHINHSACHHOCPHI_DELETE_SUCCESSS",
    CHINHSACHHOCPHI_DELETE_ERROR = "CHINHSACHHOCPHI_DELETE_ERROR",

    CHINHSACHHOCPHI_CHANGE_SELECTED_IDS = "CHINHSACHHOCPHI_CHANGE_SELECTED_IDS"

}

export interface ChinhSachHocPhiLoadStartAction {
    type: ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_LOAD_START
}
export interface ChinhSachHocPhiLoadSuccessAction {
    type: ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_LOAD_SUCCESS,
    payload: sf_chinhsachhocphi[]
}
export interface ChinhSachHocPhiLoadErrorAction {
    type: ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_LOAD_ERROR,
    payload: string
}

export interface ChinhSachHocPhiShowDetailModalAction {
    type: ChinhSachHocPhiActionTypeIds.NHOMKHOANOP_SHOW_DETAIL_MODAL,
    payload?: sf_chinhsachhocphi
}
export interface ChinhSachHocPhiCloseDetailModalAction {
    type: ChinhSachHocPhiActionTypeIds.NHOMKHOANOP_CLOSE_DETAIL_MODAL,
}
export interface ChinhSachHocPhiShowDeleteConfirmAction {
    type: ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_SHOW_DELETE_CONFIRM,
    payload: boolean
}
export interface ChinhSachHocPhiSaveStartAction {
    type: ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_SAVE_START,
    payload: sf_chinhsachhocphi
}
export interface ChinhSachHocPhiSaveSuccessAction {
    type: ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_SAVE_SUCCESS,
    payload: sf_chinhsachhocphi
}
export interface ChinhSachHocPhiSaveErrorAction {
    type: ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_SAVE_ERROR,
    payload: string
}
export interface ChinhSachHocPhiDeleteStartAction {
    type: ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_DELETE_START,
    payload: number[]
}
export interface ChinhSachHocPhiDeleteSuccessAction {
    type: ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_DELETE_SUCCESSS,
    payload: number[]
}
export interface ChinhSachHocPhiDeleteErrorAction {
    type: ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_DELETE_ERROR,
    payload: string
}

export interface ChinhSachHocPhiChangeSelectedIdsAction {
    type: ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_CHANGE_SELECTED_IDS,
    payload: number[]
}
export type ChinhSachHocPhiActionTypes =
    ChinhSachHocPhiLoadStartAction | ChinhSachHocPhiLoadSuccessAction | ChinhSachHocPhiLoadErrorAction |
    ChinhSachHocPhiShowDetailModalAction | ChinhSachHocPhiShowDeleteConfirmAction |
    ChinhSachHocPhiSaveStartAction | ChinhSachHocPhiSaveSuccessAction | ChinhSachHocPhiSaveErrorAction |
    ChinhSachHocPhiDeleteStartAction | ChinhSachHocPhiDeleteSuccessAction | ChinhSachHocPhiDeleteErrorAction |
    ChinhSachHocPhiChangeSelectedIdsAction | ChinhSachHocPhiCloseDetailModalAction