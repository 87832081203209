import { sf_chinhsachhocphi } from "../../models/response/category/sf_chinhsachhocphi"
import {
    ChinhSachHocPhiActionTypeIds,
    ChinhSachHocPhiLoadStartAction, ChinhSachHocPhiLoadSuccessAction, ChinhSachHocPhiLoadErrorAction, ChinhSachHocPhiShowDetailModalAction,
    ChinhSachHocPhiSaveStartAction, ChinhSachHocPhiSaveSuccessAction, ChinhSachHocPhiSaveErrorAction, ChinhSachHocPhiDeleteStartAction, ChinhSachHocPhiDeleteSuccessAction, ChinhSachHocPhiDeleteErrorAction, ChinhSachHocPhiChangeSelectedIdsAction, ChinhSachHocPhiShowDeleteConfirmAction, ChinhSachHocPhiCloseDetailModalAction
} from "../action-types/chinhSachHocPhiActionTypes"

export const loadChinhSachHocPhiStart = (): ChinhSachHocPhiLoadStartAction => {
    return {
        type: ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_LOAD_START
    }
}

export const loadChinhSachHocPhiSuccess = (payload: sf_chinhsachhocphi[]): ChinhSachHocPhiLoadSuccessAction => {
    return {
        type: ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_LOAD_SUCCESS,
        payload: payload
    }
}
export const loadChinhSachHocPhiError = (payload: string): ChinhSachHocPhiLoadErrorAction => {
    return {
        type: ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_LOAD_ERROR,
        payload: payload
    }
}

export const showChinhSachHocPhiDetailModal = (payload?: sf_chinhsachhocphi): ChinhSachHocPhiShowDetailModalAction => {
    return {
        type: ChinhSachHocPhiActionTypeIds.NHOMKHOANOP_SHOW_DETAIL_MODAL,
        payload: payload
    }
}

export const closeChinhSachHocPhiDetailModal = (): ChinhSachHocPhiCloseDetailModalAction => {
    return {
        type: ChinhSachHocPhiActionTypeIds.NHOMKHOANOP_CLOSE_DETAIL_MODAL,
    }
}

export const showChinhSachHocPhiDeleteConfirm = (payload: boolean): ChinhSachHocPhiShowDeleteConfirmAction => {
    return {
        type: ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_SHOW_DELETE_CONFIRM,
        payload: payload
    }
}

export const saveChinhSachHocPhiStart = (payload: sf_chinhsachhocphi): ChinhSachHocPhiSaveStartAction => {
    return {
        type: ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_SAVE_START,
        payload: payload
    }
}

export const saveChinhSachHocPhiSuccess = (payload: sf_chinhsachhocphi): ChinhSachHocPhiSaveSuccessAction => {
    return {
        type: ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_SAVE_SUCCESS,
        payload: payload
    }
}
export const saveChinhSachHocPhiError = (payload: string): ChinhSachHocPhiSaveErrorAction => {
    return {
        type: ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_SAVE_ERROR,
        payload: payload
    }
}

export const deleteChinhSachHocPhiStart = (payload: number[]): ChinhSachHocPhiDeleteStartAction => {
    return {
        type: ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_DELETE_START,
        payload: payload
    }
}

export const deleteChinhSachHocPhiSuccess = (payload: number[]): ChinhSachHocPhiDeleteSuccessAction => {
    return {
        type: ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_DELETE_SUCCESSS,
        payload: payload
    }
}
export const deleteChinhSachHocPhiError = (payload: string): ChinhSachHocPhiDeleteErrorAction => {
    return {
        type: ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_DELETE_ERROR,
        payload: payload
    }
}

export const changeChinhSachHocPhiSelectionIds = (payload: number[]): ChinhSachHocPhiChangeSelectedIdsAction => {
    return {
        type: ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_CHANGE_SELECTED_IDS,
        payload: payload
    }
}