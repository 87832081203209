import { call, put, takeEvery } from "redux-saga/effects"
import { chinhSachHocPhiApi } from "../../api/chinhSachHocPhiApi"
import { IBaseResponeModel } from "../../models/response/base-response"
import { ChinhSachHocPhiActionTypeIds, ChinhSachHocPhiDeleteStartAction, ChinhSachHocPhiSaveStartAction } from "../action-types/chinhSachHocPhiActionTypes"
import {
    loadChinhSachHocPhiError, loadChinhSachHocPhiSuccess, saveChinhSachHocPhiError, saveChinhSachHocPhiSuccess,
    deleteChinhSachHocPhiSuccess, deleteChinhSachHocPhiError
} from "../actions/chinhSachHocPhiActions"
export function* chinhSachHocPhiSaga(): any {
    yield takeEvery(ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_LOAD_START, loadChinhSachHocPhiStartWorker)
    yield takeEvery(ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_SAVE_START, saveChinhSachHocPhiStartWorker)
    yield takeEvery(ChinhSachHocPhiActionTypeIds.CHINHSACHHOCPHI_DELETE_START, deleteChinhSachHocPhiStartWorker)
}
function* loadChinhSachHocPhiStartWorker() {
    const res: IBaseResponeModel = yield call(chinhSachHocPhiApi.select_all)
    if (res.is_success) {
        yield put(loadChinhSachHocPhiSuccess(res.data))
    } else {
        yield put(loadChinhSachHocPhiError(res.message || ""))
    }
}
function* saveChinhSachHocPhiStartWorker(data: ChinhSachHocPhiSaveStartAction) {
    let res: IBaseResponeModel;
    if (data.payload.id > 0) {
        res = yield call(chinhSachHocPhiApi.update, data.payload)
    } else {
        res = yield call(chinhSachHocPhiApi.insert, data.payload)
    }

    if (res.is_success) {
        yield put(saveChinhSachHocPhiSuccess(data.payload))
        yield call(loadChinhSachHocPhiStartWorker)
    } else {
        yield put(saveChinhSachHocPhiError(res.message || ""))
    }
}
function* deleteChinhSachHocPhiStartWorker(data: ChinhSachHocPhiDeleteStartAction) {
    const res: IBaseResponeModel = yield call(chinhSachHocPhiApi.delete, data.payload)
    if (res.is_success) {
        yield put(deleteChinhSachHocPhiSuccess(data.payload))
        yield call(loadChinhSachHocPhiStartWorker)
    } else {
        yield put(deleteChinhSachHocPhiError(res.message || ""))
    }
}